<template>
  <div class="login_container">
    <!-- 头部 -->
    <!-- 头部 -->
    <van-cell is-link to="/HCardL" center value="切换就诊卡">
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span class="custom-title">{{ userName }}</span>
      </template>
      <template slot="label">
        <span>{{ cardNo }}</span>
      </template>
      <van-icon
        slot="right-icon"
        class-prefix="icon"
        name="qiehuan"
        class="iconfont"
        color="#969799"
      />
    </van-cell>

    <van-cell center>
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span style="color: #57c4b7; font-size: 16px">{{ patientName }}</span>
        <span class="spanStyle">{{ deptName }}</span>
        <span class="spanStyle">{{ bedNo }}</span>
        <span style="margin-left: 8px">{{ inpatientStatus }}</span>
      </template>
      <template slot="label">
        <div>
          <div>
            住院号：{{ inpatientNo }}
            <div style="float: right">入院日期：{{ inpatientDate }}</div>
          </div>
          <div>出院日期：{{ dischargeDate }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell>
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <div>
          预交总金额：{{ prepayCost }}
          <div style="float: right">
            总费用：
            <span style="color: #57c4b7">{{ totalCost }}</span>
          </div>
        </div>
      </template>
      <template slot="label">
        <div style="font-size: 14px">
          预交金余额：
          <span style="color: red">{{ balance }}</span>
        </div>
      </template>
    </van-cell>

    <div>
      <van-cell title="费用清单" is-link @click="onQHFeeL" />
      <van-cell title="预交记录" is-link @click="onQHFeeR" />
    </div>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>
<script>
import { Toast } from "vant"; //弹出框
import store from "../../store";
import ajax from "../../lib/ajax";
// let patientid = store.getters["store/patientid"];
// let patientid = "0000008866";
let inpatientSeriNo;
export default {
  data() {
    return {
      value1: 0,
      show: false,
      userName: store.getters["store/userName"],
      cardNo: store.getters["store/cardNo"], //声明就诊卡号,
      // DepName: "暂无数据",
      bedNo: "暂无数据", //床位号
      inpatientStatus: "暂无数据", //住院状态
      patientName: "暂无数据", //住院信息:姓名
      deptName: "暂无数据", //住院信息:科室名
      inpatientNo: "暂无数据", //住院信息:住院号
      dischargeDate: "暂无数据", //出院时间
      inpatientDate: "暂无数据", //住院信息:入院日期,
      prepayCost: "暂无数据", //预缴总金额
      totalCost: "暂无数据", //费用总额
      balance: "暂无数据", //余额
      moneyValue: "",
      oneBtnStyle: "defaultBtn",
      twoBtnStyle: "defaultBtn",
      threeBtnStyle: "defaultBtn",
      drName: "",
      disabled: false,
      wxPayInfo: {
        timestamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
    };
  },
  created() {
    let userId = store.getters["store/userId"];
    console.log(userId);
    let idcard = store.getters["store/idcard"];
    ajax
       ajax
      .post("/Api/InHospitalService/GetInHospitalInfo", {
        userID: userId,
        userName: this.userName,
        idCard: idcard})
      .then((res) => {
        const code = res.data.result.resultCode;
        console.log(res.data.result);
        if (code === "-1") {
          this.$dialog
            .alert({
              message: "暂未找到您的住院记录",
              confirmButtonText: "返回",
            })
            .then(() => {
              // on confirm
              this.$router
                .replace({
                  path: "/QHCardL",
                })
                .catch((err) => {
                  console.log(err);
                });
            });
        } else {
           const list = res.data.result.inpatients[0];
          this.patientName = list.patientName; //住院信息:姓名
          this.deptName = list.deptName; //住院信息:科室名
          this.bedNo = list.bedNo; //床位号
          this.inpatientStatus =
            list.inState == 'I' ? "未出院" : "已出院"; //住院状态
          this.state = list.inpatientStatus;
          this.inpatientNo = list.patientNO; //住院信息:住院号
          this.inpatientDate = list.inDate;//住院信息:入院日期
          // this.dischargeDate =
          //   list.dischargeDate == "" || list.dischargeDate == null
          //     ? "暂无数据"
          //     : list.dischargeDate.substring(0, 10); //出院时间
          this.prepayCost = list.preCost; //预缴总金额
          this.totalCost = list.totCost; //费用总额
          this.balance = list.preCost-list.totCost; //余额
          this.drName = list.drName;
          inpatientSeriNo = list.inpatientSeriNo;
        }
        // this.list = lists;
        console.log(res.data.result);
      })
      .catch((err) => {
        console.log(err.data);
        Toast("获取失败，请稍后重试！");
      });
  },
  methods: {
    onQHFeeL() {
      store.commit("store/setInpatientNo", this.inpatientNo);
      store.commit("store/setInpatientSeriNo", inpatientSeriNo);
      this.$router
        .replace({
          path: "/QHFeeL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onQHFeeR() {
      store.commit("store/setInpatientSeriNo", inpatientSeriNo);
      this.$router
        .replace({
          path: "/QHFeeR",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.spanStyle {
  color: #57c4b7;
  /* font-size: 12px; */
  margin-left: 8px;
}
.defaultBtn {
  width: 100%;
}
.selectBtn {
  color: rgb(255, 255, 255) !important;
  border-color: rgb(0, 200, 170) !important;
  background: rgb(0, 200, 170) !important;
  margin-right: 3px;
}
</style>
